<template>
  <div class="staff-testimonial parent-bg">
    <div class="staff-testimonial--header">
      <StoryblokComponent
        v-if="blok.photo?.length === 1"
        class="staff-testimonial--photo"
        :blok="blok.photo[0]"
      />
      <div class="staff-testimonial--text size-xs">
        <p
          v-if="blok.name || blok.surname"
          class="staff-testimonial--name size-m bold"
        >
          <template
            v-if="config.public['country'] === 'cl' && blok.name?.length"
          >
            {{ blok.name.toUpperCase() }}
          </template>

          <template
            v-else
          >
            {{ blok.name }}
          </template>

          <template
            v-if="config.public['country'] !== 'es' && blok.surname?.length"
          >
            <template
              v-if="config.public['country'] === 'cl' && blok.name?.length"
            >
              {{ " "+blok.surname }}
            </template>

            <template
              v-else
            >
            {{ " "+blok.surname.toUpperCase() }}
            </template>
          </template>
        </p>
        <p v-if="blok.role">{{ blok.role }}</p>
        <p v-if="blok.details">{{ blok.details }}</p>
      </div>
    </div>

    <div v-if="blok.testimonial" class="staff-testimonial--quote">
      <SvgIcon name="func_quote" class="icon fill" />
      <blockquote class="size-m" v-html="testimonialRte"></blockquote>
    </div>
  </div>
</template>

<script setup>
const config = useRuntimeConfig();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const { blok } = toRefs(props);
const testimonialRte = useRenderRichText(blok.value.testimonial);
</script>

<style lang="scss" scoped>
// Base design for collection-tab
.staff-testimonial {
  display: flex;
  flex-direction: column;
  padding: 2.5rem col-span();
  border-radius: $radius-m;
  @include pair-1;
  @include for-tablet-landscape-up {
    padding: 4.5rem col-span(3) 4.5rem col-span();
  }
  @include for-desktop-up {
    padding-right: col-span(2);
  }

  &--header {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
    @include for-tablet-landscape-up {
      flex-direction: row;
      margin-left: col-span(2);
      align-items: center;
      text-align: left;
      margin-bottom: 4rem;
      gap: 2rem;
    }
    @include for-desktop-up {
      margin-left: col-span(1);
    }
    :deep(img) {
      max-width: 5.625rem;
      border-radius: $radius-m;
      @include for-tablet-landscape-up {
        max-width: 6.75rem;
      }
    }
  }

  &--name {
    color: var(--brand);
    margin-bottom: 0.5rem;
  }

  &--quote {
    display: flex;
    gap: 1.25rem;
    @include for-tablet-landscape-up {
      gap: 1.5rem;
      margin-left: col-span(0.75);
    }
    @include for-desktop-up {
      margin-left: 0;
    }
    blockquote {
      width: 100%;
    }
    :deep(p:after) {
      content: "\201d";
    }
    .icon {
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
      @include for-tablet-landscape-up {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
.alternate .staff-testimonial {
  @include pair-7;
}

// Design for news-content
.news-content .staff-testimonial {
  @include pair-3;
}
</style>
<style lang="scss">
.with-tabs .staff-testimonial {
  @include for-tablet-landscape-up {
    padding-left: col-span();
    padding-right: col-span();
  }
  &--header {
    @include for-tablet-landscape-up {
      margin-left: col-span();
    }
  }
  &--quote {
    @include for-tablet-landscape-up {
      margin-left: 0;
    }
  }
}
</style>
